import utils from '../app/utils.js'

const mQueries = {}

export default {
  add (key, mediaQuery) {
    if (typeof key === 'string' && typeof mediaQuery === 'string') {
      mQueries[key] = {}
      // media queries
      const mqList = window.matchMedia(mediaQuery)
      const className = `mq-${utils.kebabCase(key)}`
      const mqListener = function (mqList) {
        // listeners
        mQueries[key].mqListeners.forEach(func => func(mqList))
        // body class
        if (mqList.matches) {
          document.body.classList.add(className)
        } else {
          document.body.classList.remove(className)
        }
      }

      mqList.addEventListener('change', mqListener)
      mQueries[key] = {
        mqList,
        mqListener,
        mqListeners: []
      }
      mqListener(mqList)
      return true
    }
    return false
  },

  remove (key) {
    if (mQueries[key]) {
      mQueries[key].mqList.removeEventListener('change', mQueries[key].mqListener)
      delete mQueries[key]
      return true
    }
    return false
  },

  test (key) {
    if (mQueries[key]) {
      return mQueries[key].mqList.matches
    }
    return false
  },

  watch (key, func) {
    if (typeof key === 'string' && typeof func === 'function') {
      if (mQueries[key]) {
        mQueries[key].mqListeners.push(func)
        return true
      }
    }
    return false
  },

  unwatch (key, func) {
    if (mQueries[key]) {
      const index = mQueries[key].mqListeners.indexOf(func)
      if (index >= 0) {
        mQueries[key].mqListeners.splice(index, 1)
      }
    }
  }
}
