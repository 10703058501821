import app from './app/app.js'

import mediaQueries from './helpers/media-queries.js'

import FaqComponent from './components/faq'
import HeroComponent from './components/hero'
import TestimonialsComponent from './components/testimonials'

app
  .addComponent('faq', FaqComponent)
  .addComponent('hero', HeroComponent)
  .addComponent('testimonials', TestimonialsComponent)
  .init()

// media queries
mediaQueries.add('medium', '(min-width: 761px) and (max-width: 1024px)')
mediaQueries.add('small', '(max-width: 760px)')
