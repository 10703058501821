import BlazeSlider from 'blaze-slider'
import Component from '../app/component'
import mediaQueries from '../helpers/media-queries'

export default class TestimonialsComponent extends Component {
  constructor (el) {
    super(el)
    this.data = {
      index: 0
    }
  }

  init() {
    this.sliderSetup()
  }

  sliderSetup () {
    if (!this.refs.slider) {
      return
    }

    if (!this.refs.slides?.length) {
      return
    }

    this.slider = new BlazeSlider(
      this.refs.slider,
      {
        all: {
          loop: true,
          slidesToShow: mediaQueries.test('small') ? 1 : 2,
          slideGap: mediaQueries.test('small') ? '4.1666666rem' : '2.0833333rem',
          enableAutoplay: true,
          autoplayInterval: 6000,
          stopAutoplayOnInteraction: true
        }
      }
    )

    this.slider.onSlide((index) => {
      this.data.index = index
    })
  }
}
