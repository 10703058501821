import utils from './utils.js'

export default class Component {
  constructor (el) {
    this.el = el
    this.id = null
    this.data = {}
    this.refs = {}
    this.listeners = []

    if (el.getAttribute('data-id')) {
      this.id = el.getAttribute('data-id')
    }

    el.removeAttribute('data-component')

    // refs setup
    el.querySelectorAll('[data-ref]').forEach(el => {
      let key = el.getAttribute('data-ref')
      const keyMatch = key.match(/^\[(.+)\]$/)
      if (keyMatch) {
        key = keyMatch[1]
        if (!this.refs[key]) {
          this.refs[key] = [el]
        } else {
          this.refs[key].push(el)
        }
      } else {
        this.refs[key] = el
      }
      el.removeAttribute('data-ref')
    })

    // bind setup
    el.querySelectorAll('[data-on]').forEach(el => {
      const bind = el.getAttribute('data-on').split(':')
      el.addEventListener(bind[0], () => this[bind[1]]())
      el.removeAttribute('data-on')
    })
  }

  proxyData () {
    // set the proxy only once
    if (this.data.__isProxy) {
      return false
    }

    this.data = new Proxy(
      this.data,
      {
        get (target, key) {
          if (key === '__isProxy') {
            return true
          }
          return target[key]
        },

        set: (target, prop, value) => {
          let validation = true

          // validation
          const validateName = utils.camelCase(`validate ${prop}`)
          if (this[validateName] && typeof this[validateName] === 'function') {
            validation = !!this[validateName](value)
          }

          if (validation) {
            // set value to target object
            target[prop] = value

            // watcher
            const watcherName = utils.camelCase(`watch ${prop}`)
            if (this[watcherName] && typeof this[watcherName] === 'function') {
              this[watcherName](value)
            }
          } else {
            console.error(`Validation failed for props ${prop} for value ${typeof value}`)
          }

          return true
        }
      }
    )

    // launch watchers on component init
    for (const key in this.data) {
      // eslint-disable-next-line no-self-assign
      this.data[key] = this.data[key]
    }
  }

  init () {}

  destroy () {
    // remove every listener
    this.listeners.forEach(obj => {
      obj.target.removeEventListener(obj.event, obj.func)
    })

    // remove element from dom
    this.el.parentNode.removeChild(this.el)
  }

  setListener (target, event, func) {
    target.addEventListener(event, func)
    this.listeners.push({ target, event, func })
    return this.listeners.length - 1
  }

  removeListener (index) {
    if (typeof index === 'number') {
      const listenerObject = this.listeners[index]
      if (listenerObject) {
        listenerObject.target.removeEventListener(listenerObject.event, listenerObject.func)
        this.listeners.splice(index, 1)
        return true
      }
    }
    return false
  }
}
