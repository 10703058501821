let initCompleted = false
const components = {}

export default {
  async init () {
    if (initCompleted) {
      return Promise.resolve()
    }

    return Promise.resolve()
      .then(() => this.documentReady())
      .then(() => this.parseComponents())
      .then(() => {
        initCompleted = true
      })
  },

  documentReady () {
    if (initCompleted) {
      return Promise.resolve()
    }

    return new Promise(resolve => {
      if (['interactive', 'complete'].includes(document.readyState)) {
        resolve()
      } else {
        document.addEventListener('DOMContentLoaded', function () {
          resolve()
        })
      }
    })
  },

  addComponent (key, obj) {
    // add to components list
    if (typeof key === 'string' && typeof obj === 'function') {
      components[key] = obj
    }

    return this
  },

  parseComponents (container) {
    // set container
    let containerEl = document
    if (typeof container === 'string') {
      containerEl = document.querySelector(container)
    }
    if (container instanceof HTMLElement) {
      containerEl = container
    }

    const componentsElements = Array.from(containerEl.querySelectorAll('[data-component]'))
    componentsElements.forEach(el => {
      const componentName = el.getAttribute('data-component')
      if (components[componentName]) {
        const componentInstance = new components[componentName](el)
        componentInstance.proxyData()
        componentInstance.init()
      }
    })
  }
}
