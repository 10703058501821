import BlazeSlider from 'blaze-slider'
import GLightbox from 'glightbox'
import Component from '../app/component'

export default class HeroComponent extends Component {
  constructor (el) {
    super(el)
    this.data = {
      index: 0
    }
  }

  init() {
    this.sliderSetup()

    if (this.refs.buttons?.length) {
      this.refs.buttons.forEach((el) => {
        el.addEventListener('click', (e) => {
          const type = el.dataset.lightboxType
          const href = el.dataset.lightboxHref
          if (type && href) {
            e.preventDefault()
            const gallery = GLightbox({
              elements: [{
                href,
                type
              }],
              touchNavigation: true,
              autoplayVideos: true
            })
            gallery.open()
          }
        })
      })
    }
  }

  sliderSetup() {
    if (!this.refs.slider) {
      return
    }

    if (!this.refs.slides?.length) {
      return
    }

    this.slider = new BlazeSlider(
      this.refs.slider,
      {
        all: {
          loop: true,
          slidesToShow: 1,
          slideGap: '1px'
        }
      }
    )

    // window.slider = this.slider

    this.slider.onSlide((index) => {
      this.data.index = index
    })

    if (this.refs.next) {
      this.refs.next.addEventListener('pointerup', () => {
        this.slider.next()
      })
    }
    
    if (this.refs.prev) {
      this.refs.prev.addEventListener('pointerup', () => {
        this.slider.prev()
      })
    }
  }

  watchIndex(value) {
    if (this.refs?.contentSlides?.[value]) {
      this.refs.contentSlides.forEach((el, i) => {
        if (i === value) {
          el.classList.remove(el.dataset.hideClass)
        }
        else {
          el.classList.add(el.dataset.hideClass)
        }
      })
    }
  }
}
