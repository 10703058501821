import Component from '../app/component'

export default class FaqComponent extends Component {
  constructor (el) {
    super(el)
    this.data = {
      index: -1
    }
  }

  init() {
    if (this.refs?.items?.length) {
      this.refs.items.forEach((el) => {
        el.addEventListener('pointerup', () => {
          const index = this.refs.items.indexOf(el)
          this.data.index = index === this.data.index ? -1 : index
        })
      })
    }
  }

  watchIndex(value) {
    this.refs.items.forEach((el, i) => {
      if (i === this.data.index) {
        el.classList.add(el.parentNode.dataset.activeClass)
      }
      else {
        el.classList.remove(el.parentNode.dataset.activeClass)
      }
    })
  }
}
